<template>
  <v-container fluid class="mt-2">
    <v-card class="pa-2">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-text-field
              v-mask="{'alias': 'no-special-characters-allowed'}"
              v-model="form.name"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.categories.CATEGORY_NAME_INPUT_LABEL)]"
              :label="$_strings.categories.CATEGORY_NAME_INPUT_LABEL"
              required
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-for="(subCategory, index) in form.subCategory"
          :key="index"
          align="center"
          justify="space-between"
        >
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              fab
              x-small
              color="error"
              @click="deleteSubCategory(index, subCategory)"
            >
              <v-icon>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            class="pl-2"
            :cols="11">
            <v-text-field
              v-mask="{'alias': 'no-special-characters-allowed'}"
              dense
              v-model="subCategory.name"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.categories.SUBCATEGORY_NAME_INPUT_LABEL)]"
              :label="`${$_strings.categories.SUBCATEGORY_NAME_INPUT_LABEL} ${index + 1}`"
              required
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            offset="1"
            class="pt-0 pl-0"
          >
            <v-btn
              small
              color="primary"
              @click="pushNewSubCategory"
            >
              {{$_strings.categories.ADD_SUBCATEGORY_BUTTON_LABEL}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-btn block @click="$router.go(-1)">
              {{$_strings.common.BACK}}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto" v-if="categoryId">
            <v-btn block color="error" @click="deleteCategory">
              {{$_strings.categories.DELETE_CATEGORY_BUTTON_LABEL}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="auto">
            <v-btn block color="success" @click="submitData">
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'CategoryCreate',
  data() {
    return {
      valid: false,
      form: {
        name: '',
        subCategory: [],
      },
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async fetchDatas() {
      try {
        this.$root.$loading.show();

        const categoryReq = this.$_services.categories.getCategoryById(this.categoryId);
        const subCategoriesReq = this.$_services.categories.fetchSubCategoryList(this.categoryId, { page: 0, size: -1 });
        const [categoryRes, subCategoriesRes] = await Promise.all([categoryReq, subCategoriesReq]);
        if (categoryRes && subCategoriesRes) {
          this.form.name = categoryRes.data.name;
          this.form.subCategory = subCategoriesRes.data.contents.map((subCategory) => ({
            id: subCategory.id,
            name: subCategory.name,
          })) || [];
          this.$root.$loading.hide();
        }
      } catch (error) {
        this.$root.$loading.hide();
        this.$dialog.notify.error(error.data.message);
      }
    },
    async submitData() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (this.categoryId) {
        this.editCategory();
      } else {
        this.createNewCategory();
      }
    },
    async createNewCategory() {
      try {
        this.$root.$loading.show();
        await this.$_services.categories.create(this.form);
        this.$root.$loading.hide();
        this.$dialog.notify.success(this.$_strings.categories.SUCCESS_CREATE_CATEGORY_MESSAGE_TEXT);
        this.$router.go(-1);
      } catch (error) {
        this.$root.$loading.hide();
        this.$dialog.notify.error(error.data.message);
      }
    },
    async editCategory() {
      try {
        this.$root.$loading.show();
        await this.$_services.categories.edit(this.form, this.categoryId);
        this.$root.$loading.hide();
        this.$dialog.notify.success(this.$_strings.categories.SUCCESS_EDIT_CATEGORY_MESSAGE_TEXT);
        this.$router.go(-1);
      } catch (error) {
        this.$root.$loading.hide();
        this.$dialog.notify.error(error.data.message);
      }
    },
    deleteCategory() {
      this.$dialog.warning({
        text: this.$_strings.categories.DELETE_CATEGORY_CONFIRM_MESSAGE,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (userRes) {
          this.$root.$loading.show();
          this.$_services.categories.deleteCategory(this.categoryId)
            .then((res) => {
              this.$dialog.notify.success(this.$_strings.categories.SUCCESS_DELETE_CATEGORY_MESSAGE_TEXT);
              this.$router.go(-1);
            })
            .catch((err) => {
              if (err.data && err.data.message) {
                this.$dialog.notify.error(err.data.message);
              }
            })
            .finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
    async deleteSubCategory(index, subCategory) {
      if (!this.categoryId || !this.form.subCategory[index].id) return this.form.subCategory.splice(index, 1);
      this.$dialog.warning({
        text: this.$_strings.categories.DELETE_SUB_CATEGORY_CONFIRM_MESSAGE,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (userRes) => {
        if (!userRes) return;
        try {
          this.$root.$loading.show();
          await this.$_services.categories.deleteSubCategory(this.categoryId, subCategory.id);
          this.$root.$loading.hide();
          this.form.subCategory.splice(index, 1);
        } catch (error) {
          this.$root.$loading.hide();
        }
      });
    },
    pushNewSubCategory() {
      this.form.subCategory.push({
        name: '',
      });
    },
  },
  mounted() {
    if (this.categoryId) {
      this.fetchDatas();
    }
  },
};
</script>
